<template>
  <v-form v-model="valid" :readonly="pending" @submit.prevent="() => onSubmit()">
    <div class="d-flex flex-wrap overflow-auto flex-grow-1">
      <div style="flex: 1; flex-basis: 360px">
        <v-card-text class="form-grid">
          <floating-card-header :title="$t('general')" icon="person" class="form__field pt-0" />

          <v-text-field
            v-model="newUser.email"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.field.email') + ' *'"
            :rules="[
              (val) => !!val || $t('validation.required'),
              (val) => /.+@.+/.test(val) || $t('validation.email'),
            ]"
          />

          <v-select
            v-model="newUser.userRole"
            :items="Object.values(UserRole)"
            class="form__field"
            clearable
            :label="$t('admin.users.field.userRole') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          >
            <template #selection="{ item }">
              <v-chip :color="userRoleColor(item.raw)" label density="comfortable">{{ $t(`userRole.${item.raw}`) }}</v-chip>
            </template>

            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <v-chip :color="userRoleColor(item.raw)" label>{{ $t(`userRole.${item.raw}`) }}</v-chip>
              </v-list-item>
            </template>
          </v-select>

          <v-text-field
            v-model="newUser.firstName"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.field.firstName') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          />

          <v-text-field
            v-model="newUser.lastName"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.field.lastName') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          />

          <v-text-field
            v-model="newUser.phone"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.field.phone')"
          />

          <v-switch
            v-model="newUser.hasAccess"
            class="form__field"
            color="primary"
            :readonly="pending"
          >
            <template #label>
              <span>{{ $t('admin.users.field.hasAccess') }}</span>

              <v-tooltip width="400">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-2" icon="info_outline"/>
                </template>

                <span>{{ $t('admin.users.field.hasAccessCaption') }}</span>
              </v-tooltip>
            </template>
          </v-switch>
        </v-card-text>
      </div>

      <div style="flex: 1; flex-basis: 360px">
        <v-card-text class="form-grid">
          <floating-card-header :title="$t('admin.users.mainOrganization')" :icon="$t('admin.organizations.icon')" class="form__field pt-0" />

          <v-autocomplete
            v-model="newUser.organization"
            :items="organizations"
            item-title="description"
            item-value="_id"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.mainOrganization') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          >
            <template #selection="{ item }">
              <domain-type-badge :type="item.raw.domain" :model-title="item.raw.description" />
            </template>
            <template #item="{ props, item }">
              <v-list-item v-bind="props" :title="item.title" :prepend-icon="$t(`organizationIcon.${item.raw.type}`)">
                <template #append>
                  <v-icon class="mr-2 material-symbols-outlined" :icon="$t(`domainIcon.${item.raw.domain}`)" size="1.25rem" />
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-switch

            v-model="canLoginToOrganization"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
            :label="$t('settings.users.hasAccessCaption')"
          />

          <v-switch
            v-model="hasBasicViewScopes"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
          >
            <template #label>
              <span>{{ $t('admin.users.authorization.basicView') }}</span>

              <v-tooltip>
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-2" icon="info_outline"/>
                </template>

                <v-list density="compact" bg-color="transparent">
                  <v-list-item v-for="scope in basicViewScopes" :key="scope" class="px-0" min-height="20px" :prepend-icon="generateScopeIcon(scope)">
                    {{ $t(`scope.${scope}`) }}
                  </v-list-item>
                </v-list>
              </v-tooltip>
            </template>
          </v-switch>

          <v-switch
            v-model="hasBasicEditScopes"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
          >
            <template #label>
              <span>{{ $t('admin.users.authorization.basicEdit') }}</span>

              <v-tooltip>
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-2" icon="info_outline"/>
                </template>

                <v-list density="compact" bg-color="transparent">
                  <v-list-item v-for="scope in basicEditScopes" :key="scope" class="px-0" min-height="20px" :prepend-icon="generateScopeIcon(scope)">
                    {{ $t(`scope.${scope}`) }}
                  </v-list-item>
                </v-list>
              </v-tooltip>
            </template>
          </v-switch>

          <v-switch
            v-model="hasAdvancedViewScopes"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
          >
            <template #label>
              <span>{{ $t('admin.users.authorization.advancedView') }}</span>

              <v-tooltip>
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-2" icon="info_outline"/>
                </template>

                <v-list density="compact" bg-color="transparent">
                  <v-list-item v-for="scope in advancedViewScopes" :key="scope" class="px-0" min-height="20px" :prepend-icon="generateScopeIcon(scope)">
                    {{ $t(`scope.${scope}`) }}
                  </v-list-item>
                </v-list>
              </v-tooltip>
            </template>
          </v-switch>

          <v-switch
            v-model="hasAdvancedEditScopes"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
          >
            <template #label>
              <span>{{ $t('admin.users.authorization.advancedEdit') }}</span>

              <v-tooltip>
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-2" icon="info_outline"/>
                </template>

                <v-list density="compact" bg-color="transparent">
                  <v-list-item v-for="scope in advancedEditScopes" :key="scope" class="px-0" min-height="20px" :prepend-icon="generateScopeIcon(scope)">
                    {{ $t(`scope.${scope}`) }}
                  </v-list-item>
                </v-list>
              </v-tooltip>
            </template>
          </v-switch>

          <v-switch
            v-model="hasBatchScopes"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
          >
            <template #label>
              <span>{{ $t('admin.users.authorization.batch') }}</span>

              <v-tooltip>
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-2" icon="info_outline"/>
                </template>

                <v-list density="compact" bg-color="transparent">
                  <v-list-item v-for="scope in batchScopes" :key="scope" class="px-0" min-height="20px" :prepend-icon="generateScopeIcon(scope)">
                    {{ $t(`scope.${scope}`) }}
                  </v-list-item>
                </v-list>
              </v-tooltip>
            </template>
          </v-switch>

          <v-switch
            v-model="hasStatusScopes"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
          >
            <template #label>
              <span>{{ $t('admin.users.authorization.statusEdit') }}</span>
            </template>
          </v-switch>

          <v-switch
            v-model="isApplicationManager"
            class="form__field"
            color="primary"
            hide-details
            :readonly="pending"
            :label="$t('settings.users.isApplicationManager')"
          />
        </v-card-text>
      </div>
    </div>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="onCancel">{{ $t('cancel') }}</v-btn>
      <v-btn color="primary" type="submit" :loading="pending">{{ $t('save') }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
import { UserRole } from "~~/stores/user.store"
import { userRoleColor } from "~~/utils/color"
import {
  basicViewScopes,
  basicEditScopes,
  advancedViewScopes,
  advancedEditScopes,
  batchScopes,
} from "~~/stores/auth.store"

const emit = defineEmits<{
  (event: "ok", value: User): void
  (event: "cancel"): void
}>()

const $i18n = useI18n()

const authStore = useAuthStore()
const mainStore = useMainStore()
const userStore = useUserStore()
const organizationStore = useOrganizationStore()

const { data: organizations } = useLazyAsyncData("organizations", () => organizationStore.getAllOrganizations({ pagination: false }), { default: () => [] as Array<Organization> })

const newUser = ref<Partial<User>>({ userRole: UserRole.USER })
const canLoginToOrganization = ref(true)
const hasBasicViewScopes = ref(true)
const hasBasicEditScopes = ref(false)
const hasAdvancedViewScopes = ref(false)
const hasAdvancedEditScopes = ref(false)
const isApplicationManager = ref(false)
const hasBatchScopes = ref(false)
const hasStatusScopes = ref(false)

const valid = ref<boolean | null>(null)
const pending = ref(false)

const generateScopeIcon = (scope: string) => {
  if (scope.startsWith("CAN_VIEW")) { return "visibility" }
  if (scope.startsWith("CAN_UPDATE")) { return "edit" }
  if (scope.startsWith("CAN_CREATE")) { return "add" }
  if (scope.startsWith("CAN_DELETE")) { return "delete" }
  if (scope.startsWith("CAN_BATCH")) { return "list" }

  return "help"
}

const onSubmit = () => {
  if (!valid.value) { return }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    pending.value = true

    try {
      const user = await userStore.createUser(newUser.value)

      const scopes = [] as Array<RelationshipRequest>
      const categories = [] as Array<RelationshipRequest>

      const toAuthTuple = (scope: AuthScope, object?: string ) => ({
        user: user._id,
        relation: scope,
        object: object ?? user.organization!,
      })
      const toCategoryAuthTuple = (category: string) => ({
        user: user._id,
        relation: AuthScope.CAN_VIEW,
        object: category,
      })

      if (canLoginToOrganization.value) { scopes.push(toAuthTuple(AuthScope.CAN_LOGIN)) }
      if (isApplicationManager.value) { scopes.push(toAuthTuple(AuthScope.ADMIN)) }
      if (hasBasicViewScopes.value) {
        scopes.push(...basicViewScopes.map(scope => toAuthTuple(scope)))

        // Add all categories from the user's main organization
        const mainOrganizationCategories = await organizationStore.getOrganizationAssetCategories(user.organization!)
        categories.push(...mainOrganizationCategories.map(toCategoryAuthTuple))
      }
      if (hasBasicEditScopes.value) { scopes.push(...basicEditScopes.map(scope => toAuthTuple(scope))) }
      if (hasAdvancedViewScopes.value) { scopes.push(...advancedViewScopes.map(scope => toAuthTuple(scope))) }
      if (hasAdvancedEditScopes.value) { scopes.push(...advancedEditScopes.map(scope => toAuthTuple(scope))) }
      if (hasBatchScopes.value) { scopes.push(...batchScopes.map(scope => toAuthTuple(scope))) }
      if (hasStatusScopes.value) { scopes.push(...statusScopes.map(scope => toAuthTuple(scope))) }

      // when a user is coupled to an organization which has suppliers. It needs to receive scopes that are linked to those suppliers.
      const selectedOrganization = organizations.value.find((o) => o._id === user.organization)

      if (!selectedOrganization) { throw new Error("Organization not found") }
      if (selectedOrganization.type === "SUPPLIER" && newUser.value.userRole) {
        // get all organizations that this supplier is linked to
        const supplierRoleScope = newUser.value.userRole === UserRole.SUPPLIER_MECHANIC ?
          AuthScope.MECHANIC : newUser.value.userRole === UserRole.SUPPLIER_PLANNER ?
            AuthScope.PLANNER : newUser.value.userRole === UserRole.SUPPLIER_MANAGER ?
              AuthScope.MAINTAINER : null

        if (supplierRoleScope) {
          scopes.push(toAuthTuple(supplierRoleScope))
        }

        const supplierClients = await organizationStore.getAllSupplierClients(selectedOrganization._id)
        supplierClients.forEach(client => {
          const template = client.supplierScopeSelection

          if (!template) { return }

          const generalScopes = [
            ...((template.hasAccess && [AuthScope.CAN_LOGIN]) || []),
            ...template.ticketVisibility,
            ...template.overviews
          ]

          const mechanicUserScopes = template.mechanic
          const plannerUserScopes = template.planner
          const managerUserScopes = template.manager

          const scopeSwitch = {
            SUPPLIER_MECHANIC: mechanicUserScopes,
            SUPPLIER_PLANNER: plannerUserScopes,
            SUPPLIER_MANAGER: managerUserScopes
          }

          //from these suppliers get a single flatMapped array of the categories
          categories.push(...template.categories.map(toCategoryAuthTuple))
          //from these suppliers get a single flatMapped array of the scopes
          scopes.push(...generalScopes.map(scope => toAuthTuple(scope, client.id)))
          //from these suppliers get a single flatMapped array of the scopes tied to the userRole
          scopes.push(...scopeSwitch[newUser.value.userRole as SupplierRole].map(scope => toAuthTuple(scope, client.id)))
        })
      }

      await Promise.all(
        [
          authStore.setScopes(scopes),
          authStore.setCategories(categories),
        ]
      )

      emit("ok", user)
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      pending.value = false
    }
  })
}

const onCancel = () => {
  emit("cancel")
}
</script>